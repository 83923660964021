import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
import { NumericalInput } from '@meetup/swarm-components';
import Example from '../../components/examples/numerical';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Numerical Input`}</h1>
    <hr></hr>
    <p>{`Fills to the width of its container.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-.jsx"
      }}>{`<div style={{ width: 300 }}>
  <NumericalInput value={1} />
</div>
`}</code></pre>
    <h2>{`example`}</h2>
    <Example mdxType="Example" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      